<template>
  <v-card rounded="xl" style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); margin-top: 10px;">
    <v-card-title>
      <p style="font-size: 32px; font-weight: 700;color: #000;margin:20px 10px 0 10px">Request a quote</p>
    </v-card-title>
    <v-card-text>

      <a-form :form="form" @submit="handleSubmit" style="margin: 10px ;">
        <p style="font-size: 20px; font-weight: 700;color: #000">Shipment Information</p>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Departure">
              <a-input v-decorator="[
        'departure',
        { rules: [{ required: true, message: 'Please input the departure' }] },
      ]" placeholder="Departure choice">
                <template #prefix>
                  <span v-html="'<span class=\'mdi mdi-map-marker-radius-outline\' style=\'color: aqua;\'/>'" />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Arrival">
              <a-input v-decorator="[
        'arrival',
        { rules: [{ required: true, message: 'Please input the arrival' }] },
      ]" placeholder="Arrival">
                <template #prefix>
                  <span v-html="'<span class=\'mdi mdi-map-marker-radius-outline\' style=\'color: red;\'/>'" />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Commodity">
          <a-select v-decorator="[
        'commodity',
        { rules: [{ required: true, message: 'Please select your commodity' }] },
      ]" placeholder="Select a option and change input text above" @change="handleSelectChange">
            <a-select-option value="1">
              1
            </a-select-option>
            <a-select-option value="2">
              2
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Pickup Date">
              <a-date-picker :value="pickupDate" v-decorator="['date-picker-pickup', config]"
                @change="handlePickupDateChange" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Delivery Date">
              <a-date-picker :value="deliveryDate" v-decorator="['date-picker-delivery', config]"
                @change="handleDeliveryDateChange" style="width: 100%" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Container type">
              <a-select v-decorator="[
        'containerType',
        { rules: [{ required: true, message: 'Please select your container type' }] },
      ]" placeholder="Select a option and change input text above" @change="handleSelectChange">
                <a-select-option value="1">
                  1
                </a-select-option>
                <a-select-option value="2">
                  2
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Quantity">
              <a-input v-decorator="[
        'quantity',
        { rules: [{ required: true, message: 'Please input your quantity' }] },
      ]" placeholder="Please input your quantity" type="number" />
            </a-form-item>
          </a-col>
        </a-row>
        <span style="margin:0px 20px">
          <div style="border-bottom: 1px solid rgb(234, 236, 240);"></div>
          <!-- <h2 style="color: aqua;float:right;margin: 2px">${{ total }}</h2> -->
        </span>
        <p style="font-size: 20px; font-weight: 700;color:#000;margin:20px 10px 0 0">Contact info</p>
        <a-form-item label="Full name">
          <a-input v-decorator="[
        'full name',
        { rules: [{ required: true, message: 'Please input your name' }] },
      ]" placeholder="Please input your name" />
        </a-form-item>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Email">
              <a-input placeholder="Please input your Email" v-decorator="[
        'email',
        {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid Email',
            },
            {
              required: true,
              message: 'Please input your Email',
            },
          ],
        },
      ]" />
            </a-form-item>

          </a-col>
          <a-col :span="12">
            <a-form-item label="Phone">
              <a-input v-decorator="[
        'phone',
      ]" style="width: 100%" type="number">
                <!-- <a-select slot="addonBefore" v-decorator="['prefix', { initialValue: '86' }]" style="width: 70px">
                  <a-select-option value="86">
                    +86
                  </a-select-option>
                  <a-select-option value="87">
                    +87
                  </a-select-option>
                </a-select> -->
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Address">
          <a-input v-decorator="[
        'address',
        { rules: [{ required: true, message: 'Please input your address' }] },
      ]" placeholder="Please input your address" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 11 }">
          <a-button type="primary" html-type="submit">
            Submit <span style="margin: 0 2px;" class="mdi mdi-send"></span>
          </a-button>
        </a-form-item>
      </a-form>
    </v-card-text>

  </v-card>
</template>
<script>
import Vue from 'vue';
import {
  Carousel,
  DatePicker,
  Icon,
  Input,
  Form,
  Cascader,
  Row,
  Col,
  Select,
  Button,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(DatePicker);
Vue.use(Carousel);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Form);
Vue.use(Cascader);
Vue.use(Row);
Vue.use(Col);
Vue.use(Select);
Vue.use(Button);
const residences = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
];
export default {
  data() {
    return {
      confirmDirty: false,
      residences,
      total: 900,
      autoCompleteResult: [],
      config: {
        rules: [{ type: 'object', required: true, message: 'Please select time' }],
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      pickupDate: null,
      deliveryDate: null,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          return;
        }
      });
    },
    handlePickupDateChange(date) {
      this.pickupDate = date;
      // Xử lý logic khi ngày đặt thay đổi
    },
    handleDeliveryDateChange(date) {
      this.deliveryDate = date;
      // Xử lý logic khi ngày giao hàng thay đổi
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    },
    handleWebsiteChange(value) {
      let autoCompleteResult;
      if (!value) {
        autoCompleteResult = [];
      } else {
        autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
      }
      this.autoCompleteResult = autoCompleteResult;
    },
    handleReset() {
      this.form.resetFields();
    },
  },
};
</script>