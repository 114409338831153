var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticStyle: {
        "box-shadow": "0 2px 4px rgba(0, 0, 0, 0.2)",
        "margin-top": "10px",
      },
      attrs: { rounded: "xl" },
    },
    [
      _c("v-card-title", [
        _c(
          "p",
          {
            staticStyle: {
              "font-size": "32px",
              "font-weight": "700",
              color: "#000",
              margin: "20px 10px 0 10px",
            },
          },
          [_vm._v("Request a quote")]
        ),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "a-form",
            {
              staticStyle: { margin: "10px" },
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "20px",
                    "font-weight": "700",
                    color: "#000",
                  },
                },
                [_vm._v("Shipment Information")]
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Departure" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "departure",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input the departure",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n      'departure',\n      { rules: [{ required: true, message: 'Please input the departure' }] },\n    ]",
                              },
                            ],
                            attrs: { placeholder: "Departure choice" },
                            scopedSlots: _vm._u([
                              {
                                key: "prefix",
                                fn: function () {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          "<span class='mdi mdi-map-marker-radius-outline' style='color: aqua;'/>"
                                        ),
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Arrival" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "arrival",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input the arrival",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n      'arrival',\n      { rules: [{ required: true, message: 'Please input the arrival' }] },\n    ]",
                              },
                            ],
                            attrs: { placeholder: "Arrival" },
                            scopedSlots: _vm._u([
                              {
                                key: "prefix",
                                fn: function () {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          "<span class='mdi mdi-map-marker-radius-outline' style='color: red;'/>"
                                        ),
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Commodity" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "commodity",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "Please select your commodity",
                                },
                              ],
                            },
                          ],
                          expression:
                            "[\n      'commodity',\n      { rules: [{ required: true, message: 'Please select your commodity' }] },\n    ]",
                        },
                      ],
                      attrs: {
                        placeholder:
                          "Select a option and change input text above",
                      },
                      on: { change: _vm.handleSelectChange },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(" 1 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v(" 2 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Pickup Date" } },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["date-picker-pickup", _vm.config],
                                expression: "['date-picker-pickup', config]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { value: _vm.pickupDate },
                            on: { change: _vm.handlePickupDateChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Delivery Date" } },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["date-picker-delivery", _vm.config],
                                expression: "['date-picker-delivery', config]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { value: _vm.deliveryDate },
                            on: { change: _vm.handleDeliveryDateChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Container type" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "containerType",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message:
                                            "Please select your container type",
                                        },
                                      ],
                                    },
                                  ],
                                  expression:
                                    "[\n      'containerType',\n      { rules: [{ required: true, message: 'Please select your container type' }] },\n    ]",
                                },
                              ],
                              attrs: {
                                placeholder:
                                  "Select a option and change input text above",
                              },
                              on: { change: _vm.handleSelectChange },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v(" 1 "),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v(" 2 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Quantity" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "quantity",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input your quantity",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n      'quantity',\n      { rules: [{ required: true, message: 'Please input your quantity' }] },\n    ]",
                              },
                            ],
                            attrs: {
                              placeholder: "Please input your quantity",
                              type: "number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span", { staticStyle: { margin: "0px 20px" } }, [
                _c("div", {
                  staticStyle: {
                    "border-bottom": "1px solid rgb(234, 236, 240)",
                  },
                }),
              ]),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "20px",
                    "font-weight": "700",
                    color: "#000",
                    margin: "20px 10px 0 0",
                  },
                },
                [_vm._v("Contact info")]
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Full name" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "full name",
                          {
                            rules: [
                              {
                                required: true,
                                message: "Please input your name",
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n      'full name',\n      { rules: [{ required: true, message: 'Please input your name' }] },\n    ]",
                      },
                    ],
                    attrs: { placeholder: "Please input your name" },
                  }),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Email" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "email",
                                  {
                                    rules: [
                                      {
                                        type: "email",
                                        message: "The input is not valid Email",
                                      },
                                      {
                                        required: true,
                                        message: "Please input your Email",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n      'email',\n      {\n        rules: [\n          {\n            type: 'email',\n            message: 'The input is not valid Email',\n          },\n          {\n            required: true,\n            message: 'Please input your Email',\n          },\n        ],\n      },\n    ]",
                              },
                            ],
                            attrs: { placeholder: "Please input your Email" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "Phone" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["phone"],
                                expression: "[\n      'phone',\n    ]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { type: "number" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "Address" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address",
                          {
                            rules: [
                              {
                                required: true,
                                message: "Please input your address",
                              },
                            ],
                          },
                        ],
                        expression:
                          "[\n      'address',\n      { rules: [{ required: true, message: 'Please input your address' }] },\n    ]",
                      },
                    ],
                    attrs: { placeholder: "Please input your address" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { span: 12, offset: 11 } } },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [
                      _vm._v(" Submit "),
                      _c("span", {
                        staticClass: "mdi mdi-send",
                        staticStyle: { margin: "0 2px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }